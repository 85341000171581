import { render, staticRenderFns } from "./menuadt.vue?vue&type=template&id=4b75f238&scoped=true"
import script from "./menuadt.vue?vue&type=script&lang=js"
export * from "./menuadt.vue?vue&type=script&lang=js"
import style0 from "./menuadt.vue?vue&type=style&index=0&id=4b75f238&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b75f238",
  null
  
)

export default component.exports